<script setup lang="ts">
import type {
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from "vue";

const emit = defineEmits<{
  "update:modelValue": [value: InputHTMLAttributes["value"]];
  "update:showPassword": [value: boolean];
}>();

const props = withDefaults(
  defineProps<{
    id?: InputHTMLAttributes["id"];
    modelValue?: InputHTMLAttributes["value"];

    label?: string;
    hint?: string;
    error?: boolean;
    absoluteHint?: boolean;

    type?: InputHTMLAttributes["type"];

    wrapperAttrs?: HTMLAttributes;
    labelAttrs?: LabelHTMLAttributes;
    hintAttrs?: HTMLAttributes;

    showPassword?: boolean;
  }>(),
  {
    type: "text",

    wrapperAttrs: () => ({}),
    labelAttrs: () => ({}),
    hintAttrs: () => ({}),
  }
);

const id = computed(() => props.id || `app-input-${useId()}`);

const model = useVModel(props, "modelValue", emit, {
  passive: true,
  defaultValue: undefined,
});

const showPassword = useVModel(props, "showPassword", emit, {
  passive: true,
  defaultValue: false,
});

const field = ref<HTMLInputElement>();
const { focused } = useFocus(field);

const isLabelOnTop = computed(() => {
  if (focused.value) return true;
  if (model.value && model.value !== undefined && model.value !== "")
    return true;

  return false;
});

const focus = () => {
  field.value?.focus();
};

defineExpose({ focus });

const type = computed(() => {
  if (props.type === "password")
    return showPassword.value ? "text" : "password";
  return props.type;
});
</script>

<template>
  <div class="app-input relative" v-bind="wrapperAttrs">
    <div
      class="app-input__field-wrapper relative pt-2 flex items-center border-b-[0.0625rem] -mb-[0.0625rem]"
      :class="[
        props.error
          ? 'border-danger text-danger'
          : [
              (model && model?.length > 0) || focused
                ? 'border-black'
                : 'border-grey-600',
              'text-black',
            ],
      ]"
    >
      <label
        :for="id"
        class="app-input__label top-0 block h-2 cursor-text absolute transition-transform transform origin-top-left text-[0.5rem] leading-[0.5rem] select-none"
        :class="[
          isLabelOnTop ? '' : 'scale-[1.75]',
          isLabelOnTop ? 'translate-y-0' : 'translate-y-4',
          props.error ? 'text-danger' : 'text-grey-600',
        ]"
        v-bind="labelAttrs"
      >
        <slot name="label" v-bind="{ props }">
          {{ props.label }}
        </slot>
      </label>

      <input
        ref="field"
        :id="id"
        :type="type"
        v-model="model"
        class="app-input__field grow h-8 w-full block focus:outline-none text-sm leading-[0.875rem] bg-transparent rounded-none"
        v-bind="$attrs"
      />

      <button
        type="button"
        v-if="props.type === 'password'"
        :title="showPassword ? 'Ukryj hasło' : 'Pokaż hasło'"
        class="w-8 h-8 -me-2 p-2 flex items-center justify-center"
        @click="showPassword = !showPassword"
      >
        <Icon
          :name="showPassword ? 'ns:eye-off' : 'ns:eye'"
          class="w-[0.875rem] h-[0.875rem] [&_path]:stroke-[1.71428571429px]"
        />
      </button>
    </div>

    <p
      v-if="$slots.hint || props.hint"
      class="app-input__hint mt-[0.1875rem] text-[0.625rem] leading-[0.625rem] w-full"
      :class="[
        props.error ? 'text-danger' : 'text-grey-600',
        props.absoluteHint ? 'absolute' : 'relative',
      ]"
      v-bind="hintAttrs"
    >
      <slot name="hint" v-bind="{ props }">
        {{ props.hint }}
      </slot>
    </p>
  </div>
</template>
